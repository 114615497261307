#colorbox-1 {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
  div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box;
  }
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxOverlay-1,
#cboxWrapper-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

.legal_popover {
  .legal_popover__content {
    overflow: hidden;
    font-size: 18px;
    padding-top: 30px;
  }
  .legal_popover_title {
    font-size: 24px;
    text-align: left;
    margin-left: 0px;
  }
}

h4,
.promo-unit__headline {
  font-size: 30px;
  letter-spacing: -0.005em;
}

.welcome15-overlay-1 .legal_popover .email_input {
  margin: 0px;
  width: 100%;
}

.legal_popover {
  background: none;
}

.welcome15-overlay-1 {
  &.alt_layout1 .legal_popover {
    .text-form-text {
      border-color: #ffffff;
    }
    input {
      &[type='text'],
      &[type='tel'],
      &[type='email'],
      &[type='password'] {
        border-color: #ffffff;
      }
    }
    textarea,
    .form-text,
    select {
      border-color: #ffffff;
    }
    input {
      background-color: transparent;
      border: 1px solid #9eafff;
      box-shadow: none;
      color: #9eafff;
      height: 37px;
      line-height: normal;
      text-align: start;
      width: 290px;
      margin-left: 0;
    }
    .email_input input {
      &.form-text {
        width: 290px;
        height: 33px;
        color: #ffffff;
        margin-right: 10px;
      }
      &[type='submit'] {
        padding-left: 17px;
        padding-right: 17px;
        margin-left: 0px;
      }
    }
    .form-submit {
      border: 1px solid #9eafff;
      color: #9eafff;
    }
  }
  #cboxContent {
    padding: 20px 20px 10px;
  }
}

@media only screen and (min-width: 641px) {
  .legal_popover .email_input input {
    &[type='submit'] {
      line-height: normal;
      height: 33px;
      padding: 0 12px;
    }
    &.form-submit {
      width: auto;
      text-align: start;
      margin-left: 0;
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}

#cboxContent {
  background: none repeat scroll 0% 0%;
  overflow: hidden;
  position: relative;
}

#colorbox-1,
#cboxOverlay-1,
#cboxWrapper-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  overflow: hidden;
  box-sizing: content-box;
  height: 440px;
  width: 590px;
}

.device-mobile #colorbox-1 div {
  box-sizing: border-box;
}

#cboxOverlay-1 {
  background: #040a2b none repeat scroll 0% 0%;
  position: fixed;
  width: 100%;
  height: 100%;
}

#colorbox-1,
#cboxOverlay-1,
#cboxWrapper-1 {
  top: 0px;
  left: 0px;
  z-index: 9999;
  overflow: hidden;
}

.welcome15-overlay-1 .legal_popover {
  top: auto;
  left: auto;
  position: relative;
  padding-bottom: 40px;
}

.js-popup-offer-error-messages {
  font-size: 15px;
  position: relative;
  height: 23px;
  visibility: hidden;
}

.legal_popover .legal_popover_title__bar--full-width {
  width: 100%;
  height: 1px;
}

.welcome15-overlay-1.alt_layout1 #cboxClose {
  background-image: url('/media/export/cms/transparent_close_x.png') !important;
  background-size: 34px 34px;
  background-position: right center;
  background-size: contain;
  right: 43px !important;
  top: 25px !important;
}

html .page-utilities__signup1 {
  input[type='email']::-moz-placeholder,
  .form-text::-moz-placeholder,
  input[type='email']::-webkit-input-placeholder,
  .form-text::-webkit-input-placeholder {
    color: #ccc;
    background: transparent;
    background: none;
  }
}

.device-pc .welcome15-overlay-1.alt_layout1 {
  #cboxWrapper-1,
  &#colorbox-1 {
    width: auto;
  }
}

.welcome15-overlay-1.alt_layout1 .legal_popover {
  color: #ffffff;
  background-color: transparent;
  h4,
  .promo-unit__headline {
    font-size: 40px;
    line-height: 36px;
  }
}

#colorbox-1.welcome15-overlay-1.alt_layout1 {
  input[placeholder],
  [placeholder],
  *[placeholder] {
    color: #ffffff;
  }
  input {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: #ffffff;
    }
  }
}

.country-chooser a.country-chooser__selector {
  width: 200px;
}

.device-mobile #colorbox-1 {
  div {
    &#cboxWrapper-1,
    &#cboxContent {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      width: 100% !important;
    }
  }
  height: 1000px !important;
  left: 0 !important;
  position: fixed !important;
  width: 100% !important;
}

.email_input page-utilities__signup1 .form-submit {
  padding: 11px 0;
  text-align: center !important;
}

.device-mobile #colorbox-1.welcome15-overlay-1.alt_layout1 .email_input page-utilities__signup1 .form-submit {
  background-color: #040a2b;
}

.email_input.page-utilities__signup1 .form-submit {
  background-color: #040a2b !important;
  text-align: center !important;
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.header__headline,
.mpp__header,
.mpp__bestfor--headline,
.mb-slideshow__slide-content h2 {
  font-family: $optimaregular !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline {
    font-family: $bb-roman !important;
  }
}

.device-mobile .page-navigation .level-1 {
  text-transform: capitalize;
}
